import getCookie from "./getCookie";
import axios from "axios";

// ISO 3166-1-alpha-2 country codes
const countries = Object.fromEntries(require("./countries.json").map(i => ([i["Code"].toUpperCase(), i["Name"]])));
let country = null;
let countryPromise = null;

export const getCountry = async () => {
    if (country !== null) {
        return Promise.resolve(country);
    }

    // try cookie
    const code = getCookie("cloudfront-view-country");
    if (code && code.toUpperCase() in countries) {
        country = code.toUpperCase();
        return Promise.resolve(country);
    }

    // try ajax async
    if (countryPromise === null) {
        countryPromise = axios.head("https://www.bittorrent.com")
            .then(response => {
                const code = response.headers["cloudfront-viewer-country"];
                if (code && code.toUpperCase() in countries) {
                    country = code.toUpperCase();
                } else {
                    country = "Unknown"
                }
                return country;
            })
            .catch(_ => {
                country = "Unknown"
                return country;
            });
    }
    return countryPromise;
};
