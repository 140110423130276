import React from 'react';
import * as styles from './navPopup.module.css';
import { NavContext } from './NavContext';
import { FaCaretRight } from 'react-icons/fa';
import { IoMdGlobe } from 'react-icons/io';

class NavItem2 extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        if (this.context.selected === this.props.name) {
            this.props.togglePopup(null);
        } else {
            this.props.togglePopup(this.props.name);
        }
    }

    render() {
        if (!this.props.caret) {
            return (
                <a
                    id={this.props.id}
                    className={`btn-no-styling ${styles.navItem}`}
                    href={this.props.href}
                    rel="noopener noreferrer"
                >
                    {this.props.name}
                </a>
            );
        }

        let isSelected = this.context.isPopupOpen && this.context.selected === this.props.name;
        let rotate = isSelected ? styles.rotate : '';
        let selectedFill = isSelected ? styles.selectedFill : '';
        let icon =
            this.props.name === 'Language' ? (
                <span className={styles.globe}>
                    <IoMdGlobe />
                </span>
            ) : (
                <span className={`${styles.caret} ${rotate}`}>
                    <FaCaretRight />
                </span>
            );

        return (
            <button
                id={this.props.id}
                onClick={this.toggle}
                className={`btn-no-styling ${styles.navItem} ${selectedFill}`}
            >
                {this.props.intName}
                {icon}
            </button>
        );
    }
}

NavItem2.contextType = NavContext;

export default NavItem2;
