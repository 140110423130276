import React, { useState, useEffect, useRef } from 'react';
import useTranslations from '../utils/useTranslations';
import { CompatibilityContext } from './compatibilityContext';
import { CSSTransition } from 'react-transition-group';
import { IoMdWarning } from 'react-icons/io';
import { IconContext } from 'react-icons';
import { IoMdClose } from 'react-icons/io';
import { navigate } from '@reach/router';
import { getAndroidLink } from '../utils/getAndroidLink';

const systems = ['Win', 'Osx', 'Android'];

const CompatabilityPopup = (props) => {
    const { text } = props.pageContext;
    const t = useTranslations(text);

    const [isMac, setIsMac] = useState(false);
    const [isCatalina, setIsCatalina] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isAndroid, setIsAndroid] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [product, setProduct] = useState('Web');
    const [action, setAction] = useState('downloading');
    const [compatibleSystems, setCompatibleSystems] = useState(['Win', 'Osx']);
    const [currentSystem, setCurrentSystem] = useState('Win');
    const [productOs, setProductOs] = useState(['Win']);
    const [isLoading, setIsLoading] = useState(true);
    const [link, setLink] = useState(null);

    const loadingOverlayRef = useRef();

    useEffect(() => {
        setIsMac(window.gatsbyIsMac);
        setIsCatalina(window.gatsbyIsCatalina);
        setIsMobile(window.gatsbyIsMobile);
        setIsAndroid(window.gatsbyIsAndroid);
        setIsOpen(false);
        setProduct('Web');
        setAction('downloading');
        setCompatibleSystems(['Win', 'Osx']);
        setCurrentSystem('Win');
        setProductOs(['Win']);
        setIsLoading(false);
    }, []);

    const toggle = () => {
        setIsOpen((open) => !open);
    };

    const close = () => {
        setIsOpen(false);
    };

    const openLink = (isLocalized, forceOs) => {
        close();
        if (isLocalized) {
            let localeLink = link;
            let forceValue = forceOs;
            if (forceOs === 'catalinaWeb') {
                localeLink = '/downloads/complete';
                forceValue = 'Osx';
            }
            let locale = props.pageContext.locale === 'en' ? '' : `/${props.pageContext.locale}`;
            locale = locale.replace('_', '-');
            navigate(`${locale}${localeLink}`, {
                state: { force: forceValue },
            });
        } else if (forceOs === 'Android') {
            window.open(getAndroidLink('free'), '_blank');
        } else if (forceOs === 'iOs') {
        } else {
            window.open(link, '_blank');
        }
    };

    const compatibilityHandler = (e, action, product, productOs, link) => {
        var isCompatible = compatibilityCheck(product, productOs);
        if (isCompatible) {
            return;
        }
        e.preventDefault();

        setProduct(product); //product options -> Web, Classic, Android, Live, uTorrent Classic
        setAction(action); //action options -> downloading, buying
        setProductOs(productOs); //os option -> Win, Osx, Android
        setLink(link);
        toggle();
    };

    const compatibilityCheck = (product, productOs) => {
        let currentSystem;
        if (!isMac && !isAndroid && !isMobile) {
            currentSystem = 'Win';
        } else if (isMac && !isAndroid && !isMobile) {
            currentSystem = 'Osx';
        } else if (!isMac && isAndroid) {
            currentSystem = 'Android';
        } else if (isMobile && !isAndroid) {
            currentSystem = 'iOs';
        }

        let compatibleSystems = systems.filter(function (x) {
            return productOs.includes(x);
        });

        setCompatibleSystems(compatibleSystems);
        setCurrentSystem(currentSystem);

        //incompatible system block
        if (!productOs.includes(currentSystem)) {
            return false;
        }

        //catalina version block
        if (product === 'Classic' && isCatalina && compatibleSystems.includes('Osx')) {
            return false;
        }

        return true;
    };

    let currentSystemName = getOsName(currentSystem);
    let productOsName = getOsName(productOs);

    let productName;
    if (product.includes('uTorrent')) {
        productName = product.replace('uTorrent', 'µTorrent');
    } else {
        productName = `BitTorrent ${product}`;
    }

    //buy now, buy with crypto cases
    if (action === 'buying') {
        var text1 = (
            <>
                <p>{t(`This product is for ${productOsName}.`)}</p>
                <p>{t(`Are you sure?`)}</p>
            </>
        );

        var text2 = (
            <p>
                <button className="btn-no-styling" onClick={() => openLink(false)}>
                    {t(`Yes`)}
                </button>
                {' | '}
                <button className="btn-no-styling" onClick={close}>
                    {t(`No`)}
                </button>
            </p>
        );
    } else if (action === 'downloading' && !isMobile) {
        text1 = (
            <>
                <p>{t(`Your device isn’t compatible with ${productName} for ${productOsName}.`)}</p>
                <p>{t(`Would you like to download ${productName} for ${currentSystemName}?`)}</p>
            </>
        );

        text2 = (
            <>
                <p className="mb-5">
                    <button className="btn-no-styling" onClick={() => openLink(true, currentSystemName)}>
                        [{t(`Yes`)}]
                    </button>
                </p>
                <p className="mb-0">
                    <button className="btn-no-styling text-size-14" onClick={() => openLink(true, productOs)}>
                        [{t(`No, please let me continue from this page.`)}]
                    </button>
                </p>
            </>
        );
    }
    //Android device case
    else if (currentSystemName === 'Android') {
        text1 = (
            <>
                <p>{t(`Your device isn’t compatible with ${productName} for ${productOsName}.`)}</p>
                <p>{t(`Would you like to download BitTorrent Android?`)}</p>
            </>
        );

        text2 = (
            <>
                <p className="mb-3">
                    <button className="btn-no-styling" onClick={() => openLink(false, 'Android')}>
                        [{t(`Yes`)}]
                    </button>
                </p>
            </>
        );
    }
    //iOs device case
    else if (currentSystemName === 'iOs') {
        text1 = (
            <>
                <p>{t(`Your device isn’t compatible with ${productName} for ${productOsName}.`)}</p>
            </>
        );

        text2 = (
            <>
                <p className="mb-3">
                    <button className="btn-no-styling" onClick={() => openLink(false, 'iOs')}>
                        [{t(`Yes`)}]
                    </button>
                </p>
            </>
        );
    }
    //blocked Language case
    else if (action === 'blockedLanguage') {
        text1 = t('Your preferred language has been updated.');
        text2 = (
            <div className="mb-3">
                <p className="compatibility-text mb-0">{t('However, this page is only available in English.')}</p>
            </div>
        );
    }
    //Catalina case
    if (isCatalina && product === 'Classic' && compatibleSystems.includes('Osx')) {
        text1 = (
            <>
                <p>{t('BitTorrent Classic for Mac is not compatible with Mac OS Catalina and above.')}</p>
                <p className="compatibility-text">{t('Would you like to download BitTorrent Web for Mac?')}</p>
            </>
        );
        text2 = (
            <>
                <p className="mb-3">
                    <button className="btn-no-styling" onClick={() => openLink(true, 'catalinaWeb')}>
                        [{t(`Yes`)}]
                    </button>
                </p>
            </>
        );
    }

    //utorrent classic case
    if (product.includes('uTorrent') && !isMobile && isMac) {
        text1 = (
            <>
                <p>{t(`Your device isn’t compatible with ${productName} for ${productOsName}.`)}</p>
                <p>{t(`Would you like to download BitTorrent Web for Mac?`)}</p>
            </>
        );
        text2 = (
            <>
                <p className="mb-3">
                    <button className="btn-no-styling" onClick={() => openLink(true, 'catalinaWeb')}>
                        [{t(`Yes`)}]
                    </button>
                </p>
                <p className="mb-0">
                    <button className="btn-no-styling text-size-14" onClick={() => openLink(false, 'Win')}>
                        [{t(`No, please let me continue from this page.`)}]
                    </button>
                </p>
            </>
        );
    }

    if (product === 'btfs') {
        text1 = (
            <>
                To install BTFS on Mac or Linux systems please see the instructions
                {' '}
                <a
                    href="https://docs.btfs.io/docs/install-run-btfs20-node"
                    id="token-btfs-hero-how-to-mac-linux"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white font-weight-normal"
                >
                    <u>here</u>
                </a>
                .
            </>
        );

        text2 = <></>;
    }

    return (
        <CompatibilityContext.Provider value={compatibilityHandler}>
            {isLoading && <div className="loading-overlay" ref={loadingOverlayRef}></div>}
            {props.children}

            <CSSTransition in={isOpen} timeout={400} classNames="compatability-animation">
                <div className="compatability-popup-container">
                    <div className="compatability-popup p-2 m-3">
                        <div className="compatability-popup-inner p-2 text-center">
                            <div className="position-relative compatibility-popup-close-container">
                                <button className="compatibility-popup-close btn-no-styling" onClick={close}>
                                    <IoMdClose />
                                </button>
                            </div>
                            <div className="px-md-5 pt-3 mx-md-5">
                                <div>
                                    <IconContext.Provider
                                        value={{
                                            className: 'compatibility-icons compatibility-icons-large',
                                        }}
                                    >
                                        <IoMdWarning />
                                    </IconContext.Provider>
                                </div>
                                <div className="mt-4 mb-2">
                                    <div className="compatibility-text">{text1}</div>
                                </div>
                                <div className="mb-0">{text2}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </CompatibilityContext.Provider>
    );
};

function getOsName(currentSystem) {
    let value = currentSystem;
    if (Array.isArray(currentSystem)) {
        value = currentSystem[0];
    }
    switch (value) {
        case 'Win':
            return 'Windows';
        case 'Osx':
            return 'Mac';
        default:
            return value;
    }
}

export default CompatabilityPopup;
