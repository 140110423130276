// Only one item MUST have the "default: true" key

module.exports = {
    de: {
        path: `de`,
        locale: `de-DE`,
        dateFormat: `DD.MM.YYYY`,
        siteLanguage: `de`,
        ogLanguage: `de_DE`,
        defaultTitle: `German`,
        defaultDescription: `BitTorrent Germany`,
    },
    en: {
        default: true,
        path: `en`,
        locale: `en-US`,
        dateFormat: `DD/MM/YYYY`,
        siteLanguage: `en`,
        ogLanguage: `en_US`,
        defaultTitle: `English`,
        defaultDescription: `Default BitTorrent`,
    },
    es: {
        path: `es`,
        locale: `es-ES`,
        dateFormat: `DD.MM.YYYY`,
        siteLanguage: `es`,
        ogLanguage: `es_ES`,
        defaultTitle: `Spanish`,
        defaultDescription: `BitTorrent Spain`,
    },
    fr: {
        path: `fr`,
        locale: `fr-FR`,
        dateFormat: `DD.MM.YYYY`,
        siteLanguage: `fr`,
        ogLanguage: `fr_FR`,
        defaultTitle: `French`,
        defaultDescription: `BitTorrent France`,
    },
    hi: {
        path: `hi`,
        locale: `hi-IN`,
        dateFormat: `DD.MM.YYYY`,
        siteLanguage: `hi`,
        ogLanguage: `hi_IN`,
        defaultTitle: `Hindi`,
        defaultDescription: `BitTorrent India`,
    },
    it: {
        path: `it`,
        locale: `it-IT`,
        dateFormat: `DD.MM.YYYY`,
        siteLanguage: `it`,
        ogLanguage: `it_IT`,
        defaultTitle: `Italian`,
        defaultDescription: `BitTorrent Italy`,
    },
    ja: {
        path: `ja`,
        locale: `ja-JP`,
        dateFormat: `DD.MM.YYYY`,
        siteLanguage: `ja`,
        ogLanguage: `ja_JP`,
        defaultTitle: `Japanese`,
        defaultDescription: `BitTorrent Japan`,
    },
    ko: {
        path: `ko`,
        locale: `ko-KO`,
        dateFormat: `DD.MM.YYYY`,
        siteLanguage: `ko`,
        ogLanguage: `ko_KO`,
        defaultTitle: `Korean`,
        defaultDescription: `BitTorrent Korea`,
    },
    nl: {
        path: `nl`,
        locale: `nl-NL`,
        dateFormat: `DD.MM.YYYY`,
        siteLanguage: `nl`,
        ogLanguage: `nl_NL`,
        defaultTitle: `Dutch`,
        defaultDescription: `BitTorrent Netherlands`,
    },
    pl: {
        path: `pl`,
        locale: `pl-PL`,
        dateFormat: `DD.MM.YYYY`,
        siteLanguage: `pl`,
        ogLanguage: `pl_PL`,
        defaultTitle: `Polish`,
        defaultDescription: `BitTorrent Poland`,
    },
    pt_br: {
        path: `pt-br`,
        locale: `pt-BR`,
        dateFormat: `DD.MM.YYYY`,
        siteLanguage: `pt_BR`,
        ogLanguage: `pt_BR`,
        defaultTitle: `Brazilian Portuguese`,
        defaultDescription: `BitTorrent Brazil`,
    },
    pt: {
        path: `pt`,
        locale: `pt-PT`,
        dateFormat: `DD.MM.YYYY`,
        siteLanguage: `pt`,
        ogLanguage: `pt_PT`,
        defaultTitle: `Portuguese`,
        defaultDescription: `BitTorrent Portugal`,
    },
    ru: {
        path: `ru`,
        locale: `ru-RU`,
        dateFormat: `DD.MM.YYYY`,
        siteLanguage: `ru`,
        ogLanguage: `ru_RU`,
        defaultTitle: `Russian`,
        defaultDescription: `BitTorrent Russia`,
    },
    tl: {
        path: `tl`,
        locale: `tl-PH`,
        dateFormat: `DD.MM.YYYY`,
        siteLanguage: `tl`,
        ogLanguage: `tl_PH`,
        defaultTitle: `Tagalog`,
        defaultDescription: `BitTorrent Philippines`,
    },
    tr: {
        path: `tr`,
        locale: `tr-TR`,
        dateFormat: `DD.MM.YYYY`,
        siteLanguage: `tr`,
        ogLanguage: `tr_TR`,
        defaultTitle: `Turkish`,
        defaultDescription: `BitTorrent Turkey`,
    },
    zh_cn: {
        path: `zh-cn`,
        locale: `zh-CN`,
        dateFormat: `DD.MM.YYYY`,
        siteLanguage: `zh_CN`,
        ogLanguage: `zh_CN`,
        defaultTitle: `Simplified Chinese`,
        defaultDescription: `BitTorrent Mainland China`,
    },
    zh_tw: {
        path: `zh-tw`,
        locale: `zh-TW`,
        dateFormat: `DD.MM.YYYY`,
        siteLanguage: `zh_TW`,
        ogLanguage: `zh_TW`,
        defaultTitle: `Traditional Chinese`,
        defaultDescription: `BitTorrent China`,
    },
};
