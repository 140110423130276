// extracted by mini-css-extract-plugin
export var bttHeader = "navPopup-module--btt-header--cd028";
export var caret = "navPopup-module--caret--cf9d8";
export var close = "navPopup-module--close--f3ae0";
export var cta = "navPopup-module--cta--a2e94";
export var description = "navPopup-module--description--9af10";
export var exchangesListing = "navPopup-module--exchanges-listing--717d9";
export var globe = "navPopup-module--globe--ecb1e";
export var img = "navPopup-module--img--2b2ee";
export var krakenList = "navPopup-module--kraken-list--75865";
export var navItem = "navPopup-module--navItem--f9214";
export var popup = "navPopup-module--popup--b31a2";
export var popupInner = "navPopup-module--popupInner--63023";
export var rotate = "navPopup-module--rotate--bbf57";
export var selectedFill = "navPopup-module--selectedFill--4c61a";
export var selection = "navPopup-module--selection--55995";
export var selectionDisabled = "navPopup-module--selectionDisabled--cd500";
export var selectionSystems = "navPopup-module--selectionSystems--b50cc";
export var show = "navPopup-module--show--94a23";
export var verticalLine = "navPopup-module--verticalLine--009e1";