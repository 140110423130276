import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import LocalizedLink from './localizedLink';
import useTranslations from '../utils/useTranslations';
import NavPopup from './navPopup';
import NavItem2 from './NavItem2';
import logo from '../images/logo-black.svg';
import { FaWindows } from 'react-icons/fa';
import { FaApple } from 'react-icons/fa';
import { FaAndroid } from 'react-icons/fa';
import { CompatibilityContext } from '../components/compatibilityContext';
import { unlocalizedPageCheck } from '../utils/unlocalizedPageCheck';
import utLogo from '../images/components/navpopup/utorrent-logo-black.svg';
import {
    Collapse,
    Container,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Dropdown,
} from 'reactstrap';
import { NavContext } from './NavContext';
import HelpCenterLinks from './help-center/helpCenterLinks';
import SpeedLogo from '../images/logos/speed-icon-black.svg';

const navSelections = ['Products', 'Language'];

const Navigation = (props) => {
    const { unlocalizedPath, navText } = props.pageContext;
    const t = useTranslations(navText);

    const [isOpen, setIsOpen] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selected, setSelected] = useState(null);
    const [showMobileMenuList, setShowMobileMenuList] = useState([false, false, false]);
    const [isMobile, setIsMobile] = useState(false);
    const [isSimplexSupported, setIsSimplexSupported] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        setIsMobile(window.gatsbyIsMobile);
    }, []);

    // initializeSimplexIframe(){
    //   if(typeof document !== "undefined") {
    //     var script = document.createElement('script');
    //     script.type = 'text/javascript';
    //     script.id = 'simplexFormScript'
    //     script.src = 'https://iframe.simplex-affiliates.com/form-sdk.js'
    //     script.addEventListener('load', function() {
    //       window.simplex.createForm();
    //     });
    //     document.body.appendChild(script);
    //   }
    // }
    const closeModal = (e) => {
        e.preventDefault();
        setIsModalOpen(false);
    };

    const toggle = () => {
        setIsOpen((open) => !open);
    };

    const togglePopup = (selected) => {
        if (selected !== null) {
            window.document.body.setAttribute('style', 'overflow:hidden');
            setIsPopupOpen(true);
            setSelected(selected);
        } else {
            window.document.body.setAttribute('style', 'overflow:scroll');
            setIsPopupOpen(false);
            setSelected(selected);
        }
    };

    const toggleMobile = (selected) => {
        let index = navSelections.indexOf(selected);
        let mobileMenuList = showMobileMenuList;
        mobileMenuList = mobileMenuList.map((item, i) => {
            return index === i ? !item : false;
        });
        setShowMobileMenuList(mobileMenuList);
    };

    //ref to control nav from page level
    const toggleNav = (selected) => {
        if (isMobile) {
            setIsOpen(true);
            toggleMobile(selected);
        } else {
            togglePopup(selected);
        }
    };

    const [prevToggleNavPage, setPrevToggleNavPage] = useState(props.toggleNavPage);
    if (prevToggleNavPage !== props.toggleNavPage) {
        setPrevToggleNavPage(props.toggleNavPage);
        toggleNav(props.toggleNavPage);
    }

    return (
        <>
            {isOpen && <div className="nav-mobile-bg-container" onClick={toggle}></div>}
            <Navbar
                light
                expand="md"
                style={{
                    backgroundColor: `#ffffff`,
                    boxShadow: '0px 53px 300px -25px rgba(0,0,0,0.75)',
                }}
            >
                <Container fluid>
                    <LocalizedLink
                        to="/"
                        id="mainnav-bt-logo"
                        onClick={() => {
                            togglePopup(null);
                        }}
                    >
                        <div className="navbar-brand mb-0">
                            <img src={logo} style={{ height: `30px` }} alt="BitTorrent - The Original Torrent Client" />
                        </div>
                    </LocalizedLink>
                    <NavbarToggler onClick={toggle} className="ml-auto" />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="ml-auto d-flex d-md-none nav-mobile" navbar>
                            <Dropdown
                                nav
                                inNavbar
                                isOpen={showMobileMenuList[0]}
                                toggle={() => {}}
                                onClick={() => {
                                    toggleMobile(navSelections[0]);
                                }}
                            >
                                <DropdownToggle nav caret>
                                    {t(`Products`)}
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <LocalizedLink
                                        to="/products/"
                                        id="mainnav-products-viewall-mobile"
                                        className="nav-mobile-link dropdown-item"
                                        onClick={toggle}
                                    >
                                        {t(`View All`)}
                                    </LocalizedLink>
                                    <DropdownItem divider />
                                    <h6 className="dropdown-header">{t(`Torrent Downloaders`)}</h6>
                                    <DropdownItem divider />
                                    <div className="position-relative">
                                        <FaWindows className="position-absolute nav-mobile-icon" />
                                        <LocalizedLink
                                            to="/products/win/bittorrent-web-free/"
                                            id="mainnav-products-btweb-windows-mobile"
                                            className="nav-mobile-link dropdown-item"
                                            onClick={toggle}
                                        >
                                            BitTorrent Web
                                        </LocalizedLink>
                                    </div>
                                    <div className="position-relative">
                                        <FaApple className="position-absolute nav-mobile-icon" />
                                        <LocalizedLink
                                            to="/products/mac/bittorrent-web-free/"
                                            id="mainnav-products-btweb-mac-mobile"
                                            className="nav-mobile-link dropdown-item"
                                            onClick={toggle}
                                        >
                                            BitTorrent Web
                                        </LocalizedLink>
                                    </div>
                                    <div className="position-relative">
                                        <FaWindows className="position-absolute nav-mobile-icon" />
                                        <LocalizedLink
                                            to="/products/win/bittorrent-classic-free/"
                                            id="mainnav-products-btclassic-windows-mobile"
                                            className="nav-mobile-link dropdown-item"
                                            onClick={toggle}
                                        >
                                            BitTorrent Classic
                                        </LocalizedLink>
                                    </div>
                                    <div className="position-relative">
                                        <FaAndroid className="position-absolute nav-mobile-icon" />
                                        <LocalizedLink
                                            to="/products/android/bittorrent-android-free/"
                                            id="mainnav-products-android-mobile"
                                            className="nav-mobile-link dropdown-item"
                                            onClick={toggle}
                                        >
                                            BitTorrent Android
                                        </LocalizedLink>
                                    </div>
                                    <div className="position-relative">
                                        <img src={SpeedLogo} className="position-absolute nav-mobile-icon" alt="" />
                                        <div
                                            id="mainnav-token-btspeed-mobile"
                                            className="nav-mobile-link dropdown-item disabled"
                                        >
                                            BitTorrent Speed
                                        </div>
                                    </div>

                                    <DropdownItem divider />
                                    <h6 className="dropdown-header">{t(`Decentralized Storage`)}</h6>
                                    <DropdownItem divider />
                                    <DropdownItem>
                                        <LocalizedLink
                                            to="/token/bittorrent-file-system/"
                                            id="mainnav-token-btfs-mobile"
                                            className="dropdown-item"
                                            onClick={toggle}
                                        >
                                            BitTorrent File System
                                        </LocalizedLink>
                                    </DropdownItem>

                                    <DropdownItem divider />
                                    <h6 className="dropdown-header">{t(`Content Utility`)}</h6>
                                    <DropdownItem divider />
                                    <a
                                        href="https://remote.bittorrent.com"
                                        id="mainnav-products-btremote-mobile"
                                        className="nav-mobile-link dropdown-item"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={toggle}
                                    >
                                        BitTorrent Remote
                                    </a>

                                    <DropdownItem divider />
                                    <h6 className="dropdown-header">{t(`More Products`)}</h6>
                                    <DropdownItem divider />
                                    <a
                                        href="https://remote.bittorrent.com"
                                        id="mainnav-products-ut-learnmore-mobile"
                                        className="nav-mobile-link dropdown-item"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={toggle}
                                    >
                                        <img src={utLogo} className="nav-ut-logo mobile" alt="µTorrent" />
                                    </a>
                                </DropdownMenu>
                            </Dropdown>

                            {/* <NavItem> */}
                            {/*   <a href="https://now.bt.co/" id="mainnav-getcontent-mobile" className="nav-link text-decoration-none" target="_blank" rel="noopener noreferrer" onClick={toggle}> */}
                            {/*     {t(`Get Content`)} */}
                            {/*   </a> */}
                            {/* </NavItem> */}

                            <NavItem>
                                <a
                                    href={HelpCenterLinks.homeLink}
                                    id="mainnav-help-mobile"
                                    className="nav-link text-decoration-none"
                                    rel="noopener noreferrer"
                                    onClick={toggle}
                                >
                                    {t(`Help`)}
                                </a>
                            </NavItem>

                            <Dropdown
                                nav
                                inNavbar
                                isOpen={showMobileMenuList[1]}
                                toggle={() => {}}
                                onClick={() => {
                                    toggleMobile(navSelections[1]);
                                }}
                            >
                                <DropdownToggle nav caret>
                                    {t(`Language`)}
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <div className="nav-mobile-languages">
                                        <DropdownItem onClick={toggle}>
                                            <Link
                                                to={unlocalizedPath}
                                                hrefLang="en"
                                                id="mainnav-lang-en-mobile"
                                                className="dropdown-item"
                                            >
                                                English
                                            </Link>
                                        </DropdownItem>
                                        <DropdownItem onClick={toggle}>
                                            <Link
                                                to={
                                                    unlocalizedPageCheck(unlocalizedPath)
                                                        ? unlocalizedPath
                                                        : '/zh-cn' + unlocalizedPath
                                                }
                                                hrefLang="zh-cn"
                                                id="mainnav-lang-zh-cn-mobile"
                                                className="dropdown-item"
                                            >
                                                中文（简体）
                                            </Link>
                                        </DropdownItem>
                                        <DropdownItem onClick={toggle}>
                                            <Link
                                                to={
                                                    unlocalizedPageCheck(unlocalizedPath)
                                                        ? unlocalizedPath
                                                        : '/zh-tw' + unlocalizedPath
                                                }
                                                hrefLang="zh-tw"
                                                id="mainnav-lang-zh-tw-mobile"
                                                className="dropdown-item"
                                            >
                                                中文（繁體）
                                            </Link>
                                        </DropdownItem>
                                        <DropdownItem onClick={toggle}>
                                            <Link
                                                to={
                                                    unlocalizedPageCheck(unlocalizedPath)
                                                        ? unlocalizedPath
                                                        : '/de' + unlocalizedPath
                                                }
                                                hrefLang="de"
                                                id="mainnav-lang-de-mobile"
                                                className="dropdown-item"
                                            >
                                                Deutsch
                                            </Link>
                                        </DropdownItem>
                                        <DropdownItem onClick={toggle}>
                                            <Link
                                                to={
                                                    unlocalizedPageCheck(unlocalizedPath)
                                                        ? unlocalizedPath
                                                        : '/es' + unlocalizedPath
                                                }
                                                hrefLang="es"
                                                id="mainnav-lang-es-mobile"
                                                className="dropdown-item"
                                            >
                                                Español
                                            </Link>
                                        </DropdownItem>
                                        <DropdownItem onClick={toggle}>
                                            <Link
                                                to={
                                                    unlocalizedPageCheck(unlocalizedPath)
                                                        ? unlocalizedPath
                                                        : '/fr' + unlocalizedPath
                                                }
                                                hrefLang="fr"
                                                id="mainnav-lang-fr-mobile"
                                                className="dropdown-item"
                                            >
                                                Français
                                            </Link>
                                        </DropdownItem>
                                        <DropdownItem onClick={toggle}>
                                            <Link
                                                to={
                                                    unlocalizedPageCheck(unlocalizedPath)
                                                        ? unlocalizedPath
                                                        : '/hi' + unlocalizedPath
                                                }
                                                hrefLang="hi"
                                                id="mainnav-lang-hi-mobile"
                                                className="dropdown-item"
                                            >
                                                हिन्दी
                                            </Link>
                                        </DropdownItem>
                                        <DropdownItem onClick={toggle}>
                                            <Link
                                                to={
                                                    unlocalizedPageCheck(unlocalizedPath)
                                                        ? unlocalizedPath
                                                        : '/it' + unlocalizedPath
                                                }
                                                hrefLang="it"
                                                id="mainnav-lang-it-mobile"
                                                className="dropdown-item"
                                            >
                                                Italiano
                                            </Link>
                                        </DropdownItem>
                                        <DropdownItem onClick={toggle}>
                                            <Link
                                                to={
                                                    unlocalizedPageCheck(unlocalizedPath)
                                                        ? unlocalizedPath
                                                        : '/ja' + unlocalizedPath
                                                }
                                                hrefLang="ja"
                                                id="mainnav-lang-ja-mobile"
                                                className="dropdown-item"
                                            >
                                                日本語
                                            </Link>
                                        </DropdownItem>
                                        <DropdownItem onClick={toggle}>
                                            <Link
                                                to={
                                                    unlocalizedPageCheck(unlocalizedPath)
                                                        ? unlocalizedPath
                                                        : '/ko' + unlocalizedPath
                                                }
                                                hrefLang="ko"
                                                id="mainnav-lang-ko-mobile"
                                                className="dropdown-item"
                                            >
                                                한국어
                                            </Link>
                                        </DropdownItem>
                                        <DropdownItem onClick={toggle}>
                                            <Link
                                                to={
                                                    unlocalizedPageCheck(unlocalizedPath)
                                                        ? unlocalizedPath
                                                        : '/nl' + unlocalizedPath
                                                }
                                                hrefLang="nl"
                                                id="mainnav-lang-nl-mobile"
                                                className="dropdown-item"
                                            >
                                                Nederlands
                                            </Link>
                                        </DropdownItem>
                                        <DropdownItem onClick={toggle}>
                                            <Link
                                                to={
                                                    unlocalizedPageCheck(unlocalizedPath)
                                                        ? unlocalizedPath
                                                        : '/pl' + unlocalizedPath
                                                }
                                                hrefLang="pl"
                                                id="mainnav-lang-pl-mobile"
                                                className="dropdown-item"
                                            >
                                                Polski
                                            </Link>
                                        </DropdownItem>
                                        <DropdownItem onClick={toggle}>
                                            <Link
                                                to={
                                                    unlocalizedPageCheck(unlocalizedPath)
                                                        ? unlocalizedPath
                                                        : '/pt-br' + unlocalizedPath
                                                }
                                                hrefLang="pt-br"
                                                id="mainnav-lang-pt-br-mobile"
                                                className="dropdown-item"
                                            >
                                                Português (Brasil)
                                            </Link>
                                        </DropdownItem>
                                        <DropdownItem onClick={toggle}>
                                            <Link
                                                to={
                                                    unlocalizedPageCheck(unlocalizedPath)
                                                        ? unlocalizedPath
                                                        : '/pt' + unlocalizedPath
                                                }
                                                hrefLang="pt"
                                                id="mainnav-lang-pt-mobile"
                                                className="dropdown-item"
                                            >
                                                Português
                                            </Link>
                                        </DropdownItem>
                                        <DropdownItem onClick={toggle}>
                                            <Link
                                                to={
                                                    unlocalizedPageCheck(unlocalizedPath)
                                                        ? unlocalizedPath
                                                        : '/ru' + unlocalizedPath
                                                }
                                                hrefLang="ru"
                                                id="mainnav-lang-ru-mobile"
                                                className="dropdown-item"
                                            >
                                                Русский
                                            </Link>
                                        </DropdownItem>
                                        <DropdownItem onClick={toggle}>
                                            <Link
                                                to={
                                                    unlocalizedPageCheck(unlocalizedPath)
                                                        ? unlocalizedPath
                                                        : '/tl' + unlocalizedPath
                                                }
                                                hrefLang="tl"
                                                id="mainnav-lang-tl-mobile"
                                                className="dropdown-item"
                                            >
                                                Tagalog
                                            </Link>
                                        </DropdownItem>
                                        <DropdownItem onClick={toggle}>
                                            <Link
                                                to={
                                                    unlocalizedPageCheck(unlocalizedPath)
                                                        ? unlocalizedPath
                                                        : '/tr' + unlocalizedPath
                                                }
                                                hrefLang="tr"
                                                id="mainnav-lang-tr-mobile"
                                                className="dropdown-item"
                                            >
                                                Türkçe
                                            </Link>
                                        </DropdownItem>
                                    </div>
                                </DropdownMenu>
                            </Dropdown>
                        </Nav>
                    </Collapse>

                    <NavContext.Provider value={{ isPopupOpen: isPopupOpen, selected: selected }}>
                        <div className="d-none d-md-flex ">
                            <NavItem2
                                caret
                                togglePopup={togglePopup}
                                intName={t('Products')}
                                name={navSelections[0]}
                                id="mainnav-products"
                            />
                            {/* <NavItem2 name={t('Get Content')} href="https://now.bt.co/" id="mainnav-getcontent" /> */}
                            <NavItem2 name={t('Help')} href={HelpCenterLinks.homeLink} id="mainnav-forum" />
                            <NavItem2
                                caret
                                togglePopup={togglePopup}
                                intName={t(`Language`)}
                                name={navSelections[1]}
                                id="mainnav-language"
                            />
                        </div>
                        <CompatibilityContext.Consumer>
                            {(compatibilityContext) => (
                                <div
                                    style={{
                                        visibility: isPopupOpen ? 'visible' : 'hidden',
                                    }}
                                >
                                    <NavPopup
                                        togglePopup={togglePopup}
                                        isMobile={isMobile}
                                        unlocalizedPath={unlocalizedPath}
                                        isSimplexSupported={isSimplexSupported}
                                        navText={navText}
                                        compatibilityContext={compatibilityContext}
                                        isModalOpen={isModalOpen}
                                        close={closeModal}
                                    />
                                </div>
                            )}
                        </CompatibilityContext.Consumer>
                    </NavContext.Provider>
                </Container>
            </Navbar>
        </>
    );
};

export { NavContext };

export default Navigation;
